// Here you can add other styles
// Override theme font
body {
    font-family: "Open Sans", sans-serif;
}

// For non-minimized sidebar, when you hover over nav items
body:not(.sidebar-minimized) {
    .sidebar .nav-dropdown.open {
        background-color: rgba(0, 0, 50, 0.05);
        .nav-link {
            &:hover,
            &:active {
                color: #fff;
            }
        }
    }
}

// Minimized sidebar fixes and style overrides
.sidebar-minimized .sidebar {
    // first item in the dropdown (and for items opened in full sidenav)
    .nav-dropdown.open a.nav-link,
    a.nav-link {
        color: #fff;
    }
    .nav-dropdown.open a.nav-link.disabled,
    a.nav-link.disabled {
        color: $sidebar-nav-link-disabled-color !important;
    }
    //
    li.nav-item.nav-dropdown.open ul a.nav-link,
    li.nav-item ul a.nav-link {
        // default color
        color: #000;
        // all + submenu items as you hover over them
        &:hover {
            color: #fff;
        }
    }
}

// hide border that shows up when you click the button
button.navbar-toggler:focus {
    outline: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
}

.navbar-brand {
    img {
        padding: 5px;
    }
}

.qotd {
    p {
        font-size: 1.6em;
        .quote {}
        .author {}
    }
}

// For laptops and desktops, scale the website by a factor for 125%.
// @media (min-width: 992px) {
//     html {
//         zoom: 125%;
//     }
//     .sidebar-fixed .app-header+.app-body .sidebar {
//         height: calc(80vh - 50px);
//     }
//     .aside-menu-fixed .aside-menu .tab-content {
//         height: calc(80vh - 2.375rem - 55px);
//     }
//     .app {
//         min-height: 80vh;
//     }
// }
// For laptops and desktops, update the color of the sidebar minimizer buttons
@media (min-width: 992px) {
    // minimizer button - colors
    .sidebar-minimized .sidebar .sidebar-minimizer,
    .sidebar .sidebar-minimizer {
        background-color: rgba(0, 30, 80, 0.2);
        &:hover {
            background-color: rgba(0, 30, 80, 0.3);
        }
    }
    .sidebar-minimized .sidebar .nav-link span.badge {
        display: inline-block;
    }
    .sidebar-minimized .sidebar .nav>.nav-item>.nav-link>.badge {
        display: none;
    }
    .sidebar-minimized .sidebar .nav>.nav-item:hover>.nav-link>.badge {
        display: inline-block;
    }
}

div.dropdown-menu {
    transform: translate3d(-128px, 36px, 0px) !important;
}

div.dropdown-menu {
     :first-child.dropdown-header {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
    }
     :last-child.dropdown-header {
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
    }
    div.dropdown-header {
        background-clip: padding-box;
        border: 1px solid #c8ced3;
        transform: scaleX(1.01) scaleY(1.03) translateY(0px) !important;
    }
}

.app-header .account-dropdown.nav-link {
    margin-right: 3px;
}

.app-header .account-dropdown.nav-link .badge {
    margin-left: 5px;
}

//  TODO: fix this, doesn't work. the nav is still really low on the page. (10 - 15px too low)
.app-header .navbar-nav .list-dropdown.nav-link .dropdown-menu-right {
    top: -15px !important;
}

footer {
    .footer-copyright {
        width: 100%
    }
    .footer-legal {
        width: 100%
    }
    .legal {
        font-size: 0.95em;
    }
}

@media (min-width: 700px) {
    footer {
        .footer-copyright {
            width: 50%;
        }
        .footer-legal {
            width: 50%;
            text-align: right;
        }
    }
}

@media (min-width: 576px) {
    // .card-columns {
    //     -webkit-column-count: 2;
    //     column-count: 2;
    // }
    .card-deck .card {
        flex: none;
        width: calc(50% - 30px);
    }
}

@media (min-width: 700px) {
    // .card-columns {
    //     -webkit-column-count: 2;
    //     column-count: 2;
    // }
    .card-deck .card {
        flex: none;
        width: calc(33.3% - 30px);
    }
}

@media (min-width: 850px) {
    // .card-columns {
    //     -webkit-column-count: 3;
    //     column-count: 3;
    // }
    .card-deck .card {
        flex: none;
        width: calc(25% - 30px);
    }
}

@media (min-width: 1100px) {
    // .card-columns {
    //     -webkit-column-count: 4;
    //     column-count: 4;
    // }
    .card-deck .card {
        flex: none;
        width: calc(20% - 30px);
    }
}

.card-deck .card {
    margin-bottom: 20px;
}

.hover-hide {
    background-color: rgba(0, 0, 0, 0.333);
    transition: 0.3s;
    &:hover {
        background-color: rgba(0, 0, 0, 0);
    }
}

.bg-dark-faded {
    transition: 0.2s ease;
    background: #111;
}

.channel-card-image,
.video-card-image {
    transition: 0.2s ease;
}

.content-card-interaction-area {
    overflow: hidden;
    transition: 0.2s ease;
}

.content-card-interaction-area:hover,
.content-card-interaction-area.hover {
    box-shadow: 0 0 15px #222299;
}

.content-card-interaction-area:hover .bg-dark-faded,
.content-card-interaction-area.hover .bg-dark-faded {
    background-color: #007bff;
}

.content-card-interaction-area:hover,
.content-card-interaction-area.hover {
    .channel-card-image {
        width: 107%;
        margin-top: calc(-3.5%);
        margin-bottom: calc(-3.5%);
    }
}

.page-container {
    margin-top: -1.5rem;
}

.page-padded {
    padding: 1.5rem;
}