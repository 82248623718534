.videoPlayerPage {
    background-color: #000;
}

#kumuVideoPlayer {
    // background-color: #000;
    display: block;
    iframe {
        display: block;
    }
}

#videoHeaderArea,
#videoFooterArea {
    display: none; //TODO: fix
    position: absolute;
    background-color: #FF0000;
}

#videoHeaderArea {
    top: 0;
    left: 0;
}

#videoFooterArea {
    bottom: 100;
    left: 0;
}

#chat-panel::-webkit-scrollbar {
    display: none;
    //   width: 0px;
    //   background: transparent; /* make scrollbar transparent */
}

.chat-history::-webkit-scrollbar {
    display: none;
    //   width: 0px;
    //   background: transparent; /* make scrollbar transparent */
}

#kumuContentArea {
    position: absolute;
    height: 100%;
    width: 300px;
    right: 0;
    top: 0;
    background-color: #222;
    #chatControls {
        width: 100%;
        display: flex;
        padding: 5px;
        button {
            flex-grow: 1;
            margin: 5px;
        }
    }
    #chat-panel {
        height: -moz-calc(100% - (50px));
        height: -webkit-calc(100% - (50px));
        height: calc(100% - (50px));
        width: 300px;
        position: absolute;
        top: 50px;
        right: 0;
        padding: 10px;
        padding-top: 0px;
        padding-bottom: 47px;
        overflow: hidden;
        overflow-y: scroll; // TODO: FIX: creates a blank bar on Mac OS
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
        overflow: -webkit-scrollbars-none;
    }
    .chat-messages {
        h5 {
            color: #aaa !important;
            font-weight: 600 !important;
            margin-left: 3px !important;
            margin-bottom: 3px !important;
        }
    }
    #chatInput {
        position: absolute;
        display: flex;
        bottom: 0;
        right: 0;
        width: 95%;
        margin: calc(2.5%);
        .reaction-button {
            margin-left: 5px;
        }
        input {
            flex-grow: 1;
        }
    }
}

#kumuMenu {
    position: absolute;
    display: none;
    //   right: 220px;
    //   bottom: 220px;
    left: 150px;
    bottom: 210px;
    opacity: 0.1;
    transition-duration: 0.8s;
    transition-delay: 1s;
}

#kumuMenu:hover {
    opacity: 1;
    transition-delay: 0s;
}

.menu-open-button {
    background: #eeeeee;
    border-radius: 100%;
    width: 80px;
    height: 80px;
    margin-left: -40px;
    position: absolute;
    color: #ffffff;
    text-align: center;
    line-height: 80px;
    -webkit-transition: -webkit-transform ease-out 200ms;
    transition: -webkit-transform ease-out 200ms;
    transition: transform ease-out 200ms;
    transition: transform ease-out 200ms, -webkit-transform ease-out 200ms;
}

.menu-open {
    display: none;
}

.lines {
    width: 25px;
    height: 3px;
    background: #596778;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12.5px;
    margin-top: -1.5px;
    -webkit-transition: -webkit-transform 200ms;
    transition: -webkit-transform 200ms;
    transition: transform 200ms;
    transition: transform 200ms, -webkit-transform 200ms;
}

.line-1 {
    -webkit-transform: translate3d(0, -8px, 0);
    transform: translate3d(0, -8px, 0);
}

.line-2 {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.line-3 {
    -webkit-transform: translate3d(0, 8px, 0);
    transform: translate3d(0, 8px, 0);
}

.menu-open:checked+.menu-open-button .line-1 {
    -webkit-transform: translate3d(0, 0, 0) rotate(45deg);
    transform: translate3d(0, 0, 0) rotate(45deg);
}

.menu-open:checked+.menu-open-button .line-2 {
    -webkit-transform: translate3d(0, 0, 0) scale(0.1, 1);
    transform: translate3d(0, 0, 0) scale(0.1, 1);
}

.menu-open:checked+.menu-open-button .line-3 {
    -webkit-transform: translate3d(0, 0, 0) rotate(-45deg);
    transform: translate3d(0, 0, 0) rotate(-45deg);
}

.menu-open-button {
    z-index: 2;
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -webkit-transition-duration: 400ms;
    transition-duration: 400ms;
    -webkit-transform: scale(1.1, 1.1) translate3d(0, 0, 0);
    transform: scale(1.1, 1.1) translate3d(0, 0, 0);
    cursor: pointer;
    box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
}

.menu-open-button:hover {
    -webkit-transform: scale(1.2, 1.2) translate3d(0, 0, 0);
    transform: scale(1.2, 1.2) translate3d(0, 0, 0);
}

.menu-open:checked+.menu-open-button {
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    -webkit-transition-duration: 200ms;
    transition-duration: 200ms;
    -webkit-transform: scale(0.8, 0.8) translate3d(0, 0, 0);
    transform: scale(0.8, 0.8) translate3d(0, 0, 0);
}

.menu-open:checked~.menu-item {
    -webkit-transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
    transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
}

.menu-open:checked~.menu-item:nth-child(3) {
    transition-duration: 180ms;
    -webkit-transition-duration: 180ms;
    -webkit-transform: translate3d(0.08361px, -104.99997px, 0);
    transform: translate3d(0.08361px, -104.99997px, 0);
}

.menu-open:checked~.menu-item:nth-child(4) {
    transition-duration: 280ms;
    -webkit-transition-duration: 280ms;
    -webkit-transform: translate3d(90.9466px, -52.47586px, 0);
    transform: translate3d(90.9466px, -52.47586px, 0);
}

.menu-open:checked~.menu-item:nth-child(5) {
    transition-duration: 380ms;
    -webkit-transition-duration: 380ms;
    -webkit-transform: translate3d(90.9466px, 52.47586px, 0);
    transform: translate3d(90.9466px, 52.47586px, 0);
}

.menu-open:checked~.menu-item:nth-child(6) {
    transition-duration: 480ms;
    -webkit-transition-duration: 480ms;
    -webkit-transform: translate3d(0.08361px, 104.99997px, 0);
    transform: translate3d(0.08361px, 104.99997px, 0);
}

.menu-open:checked~.menu-item:nth-child(7) {
    transition-duration: 580ms;
    -webkit-transition-duration: 580ms;
    -webkit-transform: translate3d(-90.86291px, 52.62064px, 0);
    transform: translate3d(-90.86291px, 52.62064px, 0);
}

.menu-open:checked~.menu-item:nth-child(8) {
    transition-duration: 680ms;
    -webkit-transition-duration: 680ms;
    -webkit-transform: translate3d(-91.03006px, -52.33095px, 0);
    transform: translate3d(-91.03006px, -52.33095px, 0);
}

.menu-open:checked~.menu-item:nth-child(9) {
    transition-duration: 780ms;
    -webkit-transition-duration: 780ms;
    -webkit-transform: translate3d(-0.25084px, -104.9997px, 0);
    transform: translate3d(-0.25084px, -104.9997px, 0);
}

.menu-item {
    background: #eeeeee;
    border-radius: 100%;
    width: 80px;
    height: 80px;
    margin-left: -40px;
    position: absolute;
    color: #ffffff;
    text-align: center;
    line-height: 80px;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: -webkit-transform ease-out 200ms;
    transition: -webkit-transform ease-out 200ms;
    transition: transform ease-out 200ms;
    transition: transform ease-out 200ms, -webkit-transform ease-out 200ms;
}

.menu {
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 80px;
    height: 80px;
    text-align: center;
    box-sizing: border-box;
    font-size: 26px;
}

.menu-item:hover {
    background: #eeeeee;
    color: #3290b1;
}

.menu-item:hover {
    text-decoration: none;
}

.menu-item:nth-child(3) {
    -webkit-transition-duration: 180ms;
    transition-duration: 180ms;
}

.menu-item:nth-child(4) {
    -webkit-transition-duration: 180ms;
    transition-duration: 180ms;
}

.menu-item:nth-child(5) {
    -webkit-transition-duration: 180ms;
    transition-duration: 180ms;
}

.menu-item:nth-child(6) {
    -webkit-transition-duration: 180ms;
    transition-duration: 180ms;
}

.menu-item:nth-child(7) {
    -webkit-transition-duration: 180ms;
    transition-duration: 180ms;
}

.menu-item:nth-child(8) {
    -webkit-transition-duration: 180ms;
    transition-duration: 180ms;
}

.menu-item:nth-child(9) {
    -webkit-transition-duration: 180ms;
    transition-duration: 180ms;
}

.item-1 {
    background-color: #669ae1;
    box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.item-1:hover {
    color: #669ae1;
    text-shadow: none;
}

.item-2 {
    background-color: #70cc72;
    box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.item-2:hover {
    color: #70cc72;
    text-shadow: none;
}

.item-3 {
    background-color: #fe4365;
    box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.item-3:hover {
    color: #fe4365;
    text-shadow: none;
}

.item-4 {
    background-color: #c49cde;
    box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.item-4:hover {
    color: #c49cde;
    text-shadow: none;
}

.item-5 {
    background-color: #fc913a;
    box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.item-5:hover {
    color: #fc913a;
    text-shadow: none;
}

.item-6 {
    background-color: #62c2e4;
    box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.item-6:hover {
    color: #62c2e4;
    text-shadow: none;
}