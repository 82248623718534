// Variable overrides

// Gray definitions
$white:     #fff !default;
$gray-base: #181b1e !default;
$gray-100:  #f0f3f5 !default; // lighten($gray-base, 85%);
$gray-200:  #e4e7ea !default; // lighten($gray-base, 80%);
$gray-300:  #c8ced3 !default; // lighten($gray-base, 70%);
$gray-400:  #acb4bc !default; // lighten($gray-base, 60%);
$gray-500:  #8f9ba6 !default; // lighten($gray-base, 50%);
$gray-600:  #73818f !default; // lighten($gray-base, 40%);
$gray-700:  #5c6873 !default; // lighten($gray-base, 30%);
$gray-800:  #2f353a !default; // lighten($gray-base, 10%);
$gray-900:  #23282c !default; // lighten($gray-base, 5%);
$black:     #000 !default;

// Sidebar
$sidebar-color:                       #000 !default;
$sidebar-bg:                          #f0f3f5;

// Sidebar Header
$sidebar-header-bg:                   rgba(255,255,255,.2) !default;

// Sidebar Form
$sidebar-form-bg:                     lighten($sidebar-bg,10%) !default;
$sidebar-form-color:                  #000 !default;
$sidebar-form-placeholder-color:      rgba(0,0,0,.7) !default;

// Sidebar Navigation
$sidebar-nav-color:                   #000 !default;
$sidebar-nav-title-color:             $gray-800 !default;
$sidebar-nav-link-color:              #000 !default;
$sidebar-nav-link-bg:                 transparent !default;
$sidebar-nav-link-icon-color:         $gray-600 !default;

$sidebar-nav-link-hover-color:        #fff !default;
// $sidebar-nav-link-hover-bg:           red !default;      // TODO: revisit
$sidebar-nav-link-hover-icon-color:   #fff !default;

$sidebar-nav-link-active-color:       #000 !default;
$sidebar-nav-link-active-bg:          darken($sidebar-bg, 10%) !default;
// $sidebar-nav-link-active-icon-color:  red !default;      // TODO: revisit

$sidebar-nav-link-disabled-color:       lighten($sidebar-nav-link-icon-color, 20%) !default;
$sidebar-nav-link-disabled-bg:          $sidebar-bg !default;
$sidebar-nav-link-disabled-icon-color:  lighten($sidebar-nav-link-icon-color, 20%) !default;

$sidebar-nav-dropdown-color:          #000 !default;
$sidebar-nav-dropdown-bg:             rgba(255,255,255,.2) !default;
$sidebar-nav-dropdown-indicator-color:$gray-400 !default;
$sidebar-nav-dropdown-indicator-hover-color:$sidebar-nav-link-hover-color;
// Sidebar Footer
$sidebar-footer-bg:                   rgba(255,255,255,.2) !default;
